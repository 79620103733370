import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {Field, reduxForm, formValueSelector} from 'redux-form'
import * as validate from 'utils/validate'
import {callAPI} from 'utils/api'
import DropZoneField from './DropZoneField'
import {Form, Col, Row, Button, ButtonGroup} from 'react-bootstrap'

import './RegisterProfile.scss'

const LEGAL_FORM_CHOICES = [
  ['individual', 'Физическое лицо'],
  ['entrepreneur', 'Индивидуальный предприниматель'],
  ['organization', 'Юридическое лицо'],
]

const PASSPORT_PHOTOS_FIELDS = [
  ['passport_registration', 'Страница паспорта с&nbsp;фотографией', 'image/*'],
  ['passport_main', 'Страница паспорта с&nbsp;регистрацией', 'image/*'],
  [
    'passport_selfie',
    'Запишите на&nbsp;телефон селфи-видео с&nbsp;паспортом в&nbsp;котором вы&nbsp;произносите ваш номер телефона. Должно быть видно вас и&nbsp;фото на&nbsp;паспорте.',
    'video/*',
  ],
]

const ProfileForm = ({legalForm, ...props}) => {
  return (
    <Form
      className="ProfileForm"
      onSubmit={props.handleSubmit(async ({inn, email, legal_form, ...values}) => {
        let submitValues = {
          inn,
          email,
          legal_form,
        }

        if (legal_form === 'individual') {
          submitValues = {
            ...submitValues,
            passport_registration: values.passport_registration.id,
            passport_selfie: values.passport_selfie.id,
            passport_main: values.passport_main.id,
          }
        }

        try {
          const response = await callAPI('profile/', submitValues, {}, 'put')
          if (response.status === 202) {
            props.onSubmitted(submitValues)
          }
        } catch (e) {
          console.log('e', e)
        }
      })}
    >
      <Form.Row>
        <Form.Group as={Col}>
          <Field
            className="form-control"
            placeholder="ИНН"
            name="inn"
            component="input"
            type="text"
            validate={[validate.required]}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Field
            className="form-control"
            placeholder="Электронная почта"
            name="email"
            component="input"
            type="text"
            validate={[validate.required]}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Group as={ButtonGroup} className="legalFormStatus">
            {LEGAL_FORM_CHOICES.map(([legalFormKey, legalFormLabel]) => (
              <label className={`btn btn-outline-primary ${legalForm === legalFormKey ? 'active' : ''}`}>
                {legalFormLabel}
                <Field
                  component="input"
                  type="radio"
                  name="legal_form"
                  value={legalFormKey}
                  validate={[validate.required]}
                />
              </label>
            ))}
          </Form.Group>
        </Col>
      </Form.Row>

      {legalForm === 'individual' && (
        <Fragment>
          <div style={{height: 20}} />

          {PASSPORT_PHOTOS_FIELDS.map(([key, label, mediaType]) => (
            <Form.Group as={Row}>
              <Col md={6} xs={12}>
                <label htmlFor={key} dangerouslySetInnerHTML={{__html: label}} />
              </Col>
              <Col md={6} xs={12}>
                <Field
                  name={key}
                  component={field => <DropZoneField field={field} kind={key} mediaType={mediaType} />}
                  validate={[validate.required]}
                />
              </Col>
            </Form.Group>
          ))}
          <div style={{height: 20}} />
        </Fragment>
      )}
      {(legalForm === 'organization' || legalForm === 'entrepreneur') && (
        <Fragment>
          <div style={{height: 20}} />
          <div className="bill">
            <p align="center">
              Для идентификации необходимо перевести 1₽&nbsp;с&nbsp;вашего расчетного счета в&nbsp;банке по&nbsp;этим
              реквизитам&nbsp;(
              <a target="_blank" href="https://api.legium.io/static/bill_new.pdf">
                pdf
              </a>{' '}
              или{' '}
              <a target="_blank" href="https://api.legium.io/static/bill.docx">
                docx
              </a>
              )
            </p>
          </div>
        </Fragment>
      )}

      <div className="submitWrap">
        <Button style={{minWidth: 200}} type="submit" disabled={props.valid ? '' : 'disabled'}>
          Продолжить
        </Button>
      </div>
    </Form>
  )
}

const testValues = {
  inn: '123123',
  email: 'vanya.mikhailov@gmail.com',
  legal_form: 'individual',
  passport_registration: {
    id: 'adyjzy',
    name: 'passport_registration.jpg',
  },
  passport_selfie: {
    id: 'lgedne',
    name: 'movie.mov',
  },
  passport_main: null,
}

const selector = formValueSelector('profile')

export default reduxForm({
  form: 'profile',
  // initialValues: testValues,
})(
  connect(state => ({
    legalForm: selector(state, 'legal_form'),
  }))(ProfileForm),
)
