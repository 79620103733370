import React, {useState, useEffect} from 'react';
import {PhoneNumberForm, PinCodeForm} from 'components';
import {sendPhone, sendCode, requestTokenTwillio} from 'services/authorization/firebase';
import {connect} from 'react-redux';
import {setToken} from "../../actions";

import {loadProfile, requestAuthCode, requestAuthCallCode, requestAuthToken} from 'actions'

const AUTH_TYPE = {
  FIREBASE: 'FIREBASE',
  TWILLIO: 'TWILLIO',
  ONCALL: 'ONCALL'
}

const REP = {
  SEND: false,
}

const LoginForm = ({dispatch, onSuccess}) => {
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [minutes, setMinutes ] = useState(0);
  const [seconds, setSeconds ] =  useState(30);
  const [authType, setAuthType] = useState(AUTH_TYPE.ONCALL);
  // var __firebase = false;

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });


  return (
    <div style={{ maxWidth: 300 }}>
      <PhoneNumberForm
        isHidden={phoneNumber !== null}
        onSubmit={phoneNumber => {
          // Вызов firebase
          // sendPhone(phoneNumber);
          
          dispatch(requestAuthCallCode(phoneNumber))
          setPhoneNumber(phoneNumber);
        }}
      />
      {phoneNumber !== null && (
        <>
          {authType === AUTH_TYPE.FIREBASE && (
            <PinCodeForm
              message={'Для продолжения введите код отправленный на'}
              phoneNumber={phoneNumber}
              code_length={6}
              onSubmit={code => {
                return new Promise((resolve, reject) => {
                  sendCode(code)
                    .then((token) => {
                      if (!token) reject();
                      dispatch(setToken(token));
                    })
                    .then(() => {
                      dispatch(loadProfile(true)).then(() => {
                        onSuccess();
                      });
                    })
                    .catch(() => {
                      setSeconds(0);
                    });
                })
              }}
            />
          )}

          {authType === AUTH_TYPE.TWILLIO && (
            <PinCodeForm
              message={'Для продолжения введите код отправленный на'}
              phoneNumber={phoneNumber}
              code_length={6}
              onSubmit={code => {
                return new Promise((resolve, reject) => {
                  requestTokenTwillio(phoneNumber, code)
                    .then((token) => {
                      if (!token) reject();
                      dispatch(setToken(token));
                    })
                    .then(() => {
                      dispatch(loadProfile(true)).then(() => {
                        onSuccess();
                      });
                    })
                    .catch(() => {
                      setSeconds(0);
                    });
                })
              }}
            />
          )}

          {authType === AUTH_TYPE.ONCALL && (
            <PinCodeForm
              message={'Укажите последние 4 цифры номера, с которого будет совершен звонок на'}
              phoneNumber={phoneNumber}
              code_length={4}
              onSubmit={code => {
                return new Promise((resolve, reject) => {
                  requestTokenTwillio(phoneNumber, code)
                    .then((token) => {
                      if (!token) reject();
                      dispatch(setToken(token));
                    })
                    .then(() => {
                      dispatch(loadProfile(true)).then(() => {
                        onSuccess();
                      });
                    })
                    .catch(() => {
                      setSeconds(0);
                    });
                })
              }}
            />
          )}

          <div>
            {minutes === 0 && seconds === 0 ? (
              <div style={{cursor: 'pointer'}} onClick={() => {
                const phone = phoneNumber;

                setPhoneNumber(null);

                if (REP.SEND === false) {
                  dispatch(requestAuthCode(phone));
                  setAuthType(AUTH_TYPE.TWILLIO);
                  REP.SEND = true;
                } else {
                  sendPhone(phone);
                  setAuthType(AUTH_TYPE.FIREBASE);
                }
                
                setSeconds(30)
                setTimeout(() => {setPhoneNumber(phone);}, 1000)
              }}> <strong>Отправить смс еще раз</strong> </div>
            ) : (
              <>
                Отправить смс через <b className="Mark">{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</b>
              </>
            )}

          </div>
        </>
      )}
    </div>
  )
}

export default connect(
  state => ({auth: state.auth}),
  null,
)(LoginForm)
