import React, {useState} from 'react'
import {requestAuthCode, commitLoginSignature} from 'actions'

import PinCode from './PinCode'

import './SignatureForm.scss'

const SignatureForm = ({dispatch, documentPublicId, phoneNumber, onSuccess}) => {
  const [initialized, setInitialized] = useState(false)
  return (
    <div className="Case__SignatureForm">
      {initialized ? (
        <PinCode
          phoneNumber={phoneNumber}
          onSubmit={code => {
            return new Promise((resolve, reject) => {
              dispatch(commitLoginSignature(documentPublicId, phoneNumber, code, false))
                .then(response => {
                  console.log('success')
                  onSuccess(response)
                })
                .catch(error => {
                  console.log('error', error)
                  reject()
                })
            })
          }}
        />
      ) : (
        <button
          className="SignatureForm__Sign"
          onClick={() => {
            // Здесь мы используем LoginWithSignAPI, чтобы залогинить пользователя одновременно с подписью
            dispatch(requestAuthCode(phoneNumber))
            setInitialized(true)
          }}
        >
          Подписать
        </button>
      )}
    </div>
  )
}

export default SignatureForm
