import React, {useState, useEffect, useMemo} from 'react'
import {Spinner} from 'components'
import ReactCodeInput from 'react-code-input'
import './PinCode.scss'

const validateCode = (code, l) => code && code.length === l

const triggerError = setCode => {
  setCode('')
}

const PinCodeForm = ({message, phoneNumber, code_length, onSubmit}) => {
  const [code, setCode] = useState('')
  const [inputKey, setInputKey] = useState(0)
  const [incorrectCode, setIncorrectCode] = useState(false)
  const isCodeValid = useMemo(() => validateCode(code, code_length), [code])

  useEffect(() => {
    if (isCodeValid) {
      onSubmit(code).catch(() => {
        setCode('')
        // ReactCodeInput принимает только initial value, нельзя убрать текущее значение другим способом. Поэтому мы задаем новый ключ, чтобы вызвать re-render всего компонента
        setInputKey(inputKey + 1)
        setIncorrectCode(true)
      })
    }
  }, [isCodeValid])

  return (
    <form className="PinCodeForm">
      <p>
        {message} <strong>{phoneNumber}</strong>
      </p>
      <ReactCodeInput
        key={inputKey}
        type="number"
        fields={code_length}
        disabled={isCodeValid}
        onChange={code => {
          setCode(code)
        }}
        value={code}
      />
      {isCodeValid && <Spinner />}
      {incorrectCode && !isCodeValid && <p>Incorrect code</p>}
    </form>
  )
}

export default PinCodeForm
