import React from 'react'
import './SpinnerSmall.scss'

const SpinnerSmall = () => {
  return (
    <div className="SpinnerSmall">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  )
}

export default SpinnerSmall
