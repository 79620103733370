import React, {useState, useMemo} from 'react'
import {Form} from 'react-bootstrap'
import InputPhone from 'components/Input/InputPhone'


const cleanPhoneNumber = maskedPhoneNumber =>
  maskedPhoneNumber
    .split(' ')
    .join('')
    .replace(/\-/g, '')
    .replace(/\_/g, '')

const validatePhoneNumber = phoneNumber => (phoneNumber.length >= 12 && phoneNumber.length <= 13)

const PhoneNumberForm = ({onSubmit, submitButtonText, isHidden = false}) => {
  const [phoneNumber, setPhoneNumber] = useState('')

  const isPhoneNumberValid = useMemo(() => validatePhoneNumber(phoneNumber), [phoneNumber])

  function changeNumber (value) {
      setPhoneNumber(cleanPhoneNumber(value || ''))
  }

  return (
    <Form
      style={{display: `${isHidden ? "none" : "block"}`}}
      onSubmit={e => {
        e.preventDefault()
        onSubmit(phoneNumber)
      }}
    >
      <p>Введите свой номер телефона:</p>
      <InputPhone
        id="sign-in-button"
        name="phone"
        onChange={changeNumber}
        value={phoneNumber}
        style={{ marginBottom: 20, width: '100%' }}
      />
      <Form.Control
        disabled={!isPhoneNumberValid}
        type="submit"
        style={{ width: '100%' }}
        value={submitButtonText}
      />
    </Form>
  )
}

PhoneNumberForm.defaultProps = {
  submitButtonText: 'Войти',
}

export default PhoneNumberForm
