import React, {useState, useEffect, useMemo, useRef} from 'react'
import {Spinner} from 'components'
import ReactCodeInput from './ReactCodeInput'
import RocketSpinner from './Spinner'
import './PinCode.scss'

const validateCode = code => code && code.length === 6

const PinCode = ({onSubmit}) => {
  const [code, setCode] = useState('')
  const [inputKey, setInputKey] = useState(0)
  const [incorrectCode, setIncorrectCode] = useState(false)
  const isCodeValid = useMemo(() => validateCode(code), [code])

  const formRef = useRef(null)

  useEffect(() => {
    if (isCodeValid) {
      onSubmit(code).catch(() => {
        setCode('')
        // ReactCodeInput принимает только initial value, нельзя убрать текущее значение другим способом. Поэтому мы задаем новый ключ, чтобы вызвать re-render всего компонента
        setInputKey(inputKey + 1)
        setIncorrectCode(true)
      })
    }
  }, [isCodeValid])

  return (
    <div className="Case__PinCode">
      <label className="only-mobile">Введите код из SMS</label>
      <form ref={formRef}>
        <label>Введите код из SMS</label>
        <div className="PinCodeInputs">
          <ReactCodeInput
            key={inputKey}
            type="number"
            fields={6}
            disabled={!!isCodeValid}
            onChange={code => {
              setCode(code)
            }}
            value={code}
          />
        </div>
        <div style={{width: 20, height: 20, transform: 'scale(0.8)'}}>{isCodeValid && <RocketSpinner />}</div>
      </form>
      {incorrectCode && !isCodeValid && <p className="PinCode__Incorrect">Упс! Код не подходит, попробуйте еще раз</p>}
    </div>
  )
}

export default PinCode
