import React from 'react'
import {connect} from 'react-redux'

import {Content, NewCaseForm} from 'components'

const Upload = () => {
  return (
    <Content>
      <NewCaseForm />
    </Content>
  )
}

export default connect(
  null,
  null,
)(Upload)
