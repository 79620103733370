import React from 'react'
import {connect} from 'react-redux'
import {Spinner} from 'components'
import './Username.scss'

const Username = ({auth}) => {
  if (!auth.profile || auth.loading) {
    return (
      <div style={{marginRight: 10}}>
        <Spinner />
      </div>
    )
  }
  return (
    <div className="Username" style={{fontWeight: 'bold', marginRight: 15}}>
      <p>{auth.profile ? auth.profile.username : ''}</p>
      {/* 
        {auth.profile && auth.profile.legal_name ? <p className="LegalName">{auth.profile.legal_name}</p> : null}
    */}
    </div>
  )
}

export default connect(
  state => ({auth: state.auth}),
  null,
)(Username)
