import React, {useState, Fragment, useEffect} from 'react'
import {PhoneNumberForm, PinCodeForm} from 'components'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import {sendPhone, sendCode, requestTokenTwillio} from 'services/authorization/firebase';

import {requestAuthCode, requestAuthCallCode} from 'actions'
import {requestSignatureCode, commitLoginSignature, commitLoginSignatureFirebase} from 'actions'

const AUTH_TYPE = {
  FIREBASE: 'FIREBASE',
  TWILLIO: 'TWILLIO',
  ONCALL: 'ONCALL'
}

const REP = {
  SEND: false,
}

const LoginAndSignatureForm = ({dispatch, match, documentPublicId, onSuccess}) => {
  const [initialized, setInitialized] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [minutes, setMinutes ] = useState(0)
  const [seconds, setSeconds ] =  useState(30)
  const [authType, setAuthType] = useState(AUTH_TYPE.ONCALL)

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  })
  return (
    <div className="SignatureForm">
      {initialized ? (
        <Fragment>
          <PhoneNumberForm
            isHidden={phoneNumber !== null}
            submitButtonText="Продолжить"
            onSubmit={phoneNumber => {
              dispatch(requestAuthCallCode(phoneNumber))
              // dispatch(requestAuthCode(phoneNumber))
              // sendPhone(phoneNumber)
              setPhoneNumber(phoneNumber)
            }}
          />
          {phoneNumber !== null && (
            <>
              {authType === AUTH_TYPE.FIREBASE && (
                <PinCodeForm
                  message={'Для продолжения введите код отправленный на'}
                  phoneNumber={phoneNumber}
                  code_length={6}
                  onSubmit={code => {
                    return new Promise((resolve, reject) => {
                      sendCode(code, (token) => {
                        dispatch(commitLoginSignatureFirebase(documentPublicId, phoneNumber, token, true))
                          .then(response => {
                            onSuccess(response.data)
                          })
                          .catch(error => {
                            console.log('error', error)
                            reject()
                          })
                      })
                    })
                  }}
                />
              )}
              {authType === AUTH_TYPE.TWILLIO && (
                <PinCodeForm
                  message={'Для продолжения введите код отправленный на'}
                  phoneNumber={phoneNumber}
                  code_length={6}
                  onSubmit={code => {
                    return new Promise((resolve, reject) => {
                      dispatch(commitLoginSignature(documentPublicId, phoneNumber, code, true))
                        .then(response => {
                          onSuccess(response.data)
                        })
                        .catch(error => {
                          console.log('error', error)
                          reject()
                        })
                    })
                  }}
                />
              )}

              {authType === AUTH_TYPE.ONCALL && (
                <PinCodeForm
                  message={'Укажите последние 4 цифры номера, с которого будет совершен звонок на'}
                  phoneNumber={phoneNumber}
                  code_length={4}
                  onSubmit={code => {
                    return new Promise((resolve, reject) => {
                      dispatch(commitLoginSignature(documentPublicId, phoneNumber, code, true))
                        .then(response => {
                          onSuccess(response.data)
                        })
                        .catch(error => {
                          console.log('error', error)
                          reject()
                        })
                    })
                  }}
                />
              )}

              <div>
                {minutes === 0 && seconds === 0 ? (
                  <div style={{cursor: 'pointer'}} onClick={() => {
                    // const phone = phoneNumber;
                    // setPhoneNumber(null);
                    // dispatch(requestAuthCode(phone));
                    // setAuthType(AUTH_TYPE.TWILLIO);
                    // setSeconds(59)
                    // setTimeout(() => {setPhoneNumber(phone);}, 1000)
                    
                    const phone = phoneNumber;

                    setPhoneNumber(null);

                    if (REP.SEND === false) {
                      dispatch(requestAuthCode(phone));
                      setAuthType(AUTH_TYPE.TWILLIO);
                      REP.SEND = true;
                    } else {
                      sendPhone(phone);
                      setAuthType(AUTH_TYPE.FIREBASE);
                    }
                    
                    setSeconds(30)
                    setTimeout(() => {setPhoneNumber(phone);}, 1000)
                  }}><strong>Отправить смс еще раз</strong></div>
                ) : (
                  <>
                    Отправить смс через <b className="Mark">{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</b>
                  </>
                )}
              </div>
            </>
          )}
        </Fragment>
      ) : (
        <Button
          style={{minWidth: 200}}
          onClick={() => {
            dispatch(requestSignatureCode(documentPublicId))
            setInitialized(true)
          }}
        >
          Подписать
        </Button>
      )}
    </div>
  )
}

export default connect(
  state => ({auth: state.auth}),
  null,
)(LoginAndSignatureForm)
