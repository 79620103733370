import React, {Fragment, useEffect} from 'react'
import {connect} from 'react-redux'
import {removeToken, loadProfile} from 'actions'
import {withRouter} from 'react-router'
import {Navbar, Nav, Button} from 'react-bootstrap'

import Username from './Username'
import Logo from './Logo'

const Header = ({auth, dispatch, history, location}) => {
  const isLoginPage = location.pathname.indexOf('login') != -1

  return (
    <Navbar bg="light">
      <Nav className="mr-auto">
        <Logo />
      </Nav>

      <Nav className="ml-auto" style={{alignItems: 'center'}}>
        {!isLoginPage && (
          <Fragment>
            {auth.token === null ? (
              <Button
                onClick={() => {
                  dispatch(removeToken())
                  history.push({
                    pathname: '/login',
                    state: {
                      from: {
                        pathname: location.pathname,
                        search: location.search,
                      },
                    },
                  })
                }}
              >
                Вход
              </Button>
            ) : (
              <Fragment>
                <Username />
                <Button
                  onClick={() => {
                    dispatch(removeToken())
                  }}
                >
                  Выйти
                </Button>
              </Fragment>
            )}
          </Fragment>
        )}
      </Nav>
    </Navbar>
  )
}

export default connect(
  state => ({auth: state.auth}),
  null,
)(withRouter(Header))
