import React, {Fragment} from 'react'
import {Content} from 'components'

const IsSigned = props => {
  console.log('Content', Content)
  return (
    <Content>
      <div className="IsSigned">
        <h1>Документ уже подписан</h1>
        <p>Войдите в личный кабинет, чтобы открыть страницу.</p>
      </div>
    </Content>
  )
}

export default IsSigned
