import {AUTH_TOKEN_KEY} from 'utils/const'

export const initialState = {
  token: localStorage.getItem(AUTH_TOKEN_KEY),
  loading: true,
  profile: null,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'AUTH/SET_TOKEN':
      localStorage.setItem(AUTH_TOKEN_KEY, action.token)
      return {...state, token: action.token}
    case 'AUTH/LOADING':
      return {...state, loading: true}
    case 'AUTH/ERROR':
    case 'AUTH/REMOVE_TOKEN':
      localStorage.removeItem(AUTH_TOKEN_KEY)
      return {...state, token: null, loading: false, profile: null}
    case 'AUTH/LOADED':
      return {...state, profile: action.profile, loading: false}
    default:
      return state
  }
}
