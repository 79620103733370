import axios from 'axios'
// import {callAPI} from 'utils/api'
import {API_DOMAIN} from 'utils/const'
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import {setToken} from "../../../actions";

// const firebaseConfig = {
//   apiKey: "AIzaSyD07gYMjSVQ-gu7b23DYUaQvgHYuBZakio",
//   authDomain: "legium-d3870.firebaseapp.com",
//   databaseURL: "https://legium-d3870.firebaseio.com",
//   projectId: "legium-d3870",
//   storageBucket: "legium-d3870.appspot.com",
//   messagingSenderId: "299502800409",
//   appId: "1:299502800409:web:31c3d089de7dcb13c92488",
//   measurementId: "G-FBD7XMBG1N"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyA3Scx-JXy9oPYaCmxcf_5939o-L3bRxO4",
//   authDomain: "legium-dev.firebaseapp.com",
//   projectId: "legium-dev",
//   storageBucket: "legium-dev.appspot.com",
//   messagingSenderId: "325596824335",
//   appId: "1:325596824335:web:0b48537671ae0d2104347a",
//   measurementId: "G-DQQYWS4TDY"
// };

// let confirmationResult = {};

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// auth.settings.appVerificationDisabledForTesting = false; // тестовый режим верификации

export const sendPhone = (phone) => {
  const appVerifier = new RecaptchaVerifier('sign-in-button', {
    'size': 'invisible'
  }, auth);

  signInWithPhoneNumber(auth, phone, appVerifier)
    .then(result => {
      confirmationResult = result;
    })
};

export const requestTokenTwillio = (phoneNumber, code) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${API_DOMAIN}/login/`, {
        phone: phoneNumber,
        pin: code,
      })
      if (response.status === 200) {
        resolve(response.data.token)
      }
    } catch (error) {
      reject();
    }
  })
}
const requestToken = (phoneNumber, accessToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${API_DOMAIN}/login/pin/firebase/`, {
        phone: phoneNumber,
        accessToken: accessToken,
      })
      if (response.status === 200) {
        resolve(response.data.token)
      }
    } catch (error) {
      reject();
    }
  })
}

export const sendCode = (code, callback) => {
  return new Promise((resolve) => {
    confirmationResult.confirm(code).then((result) => {
      if (callback) {
        callback(result.user.stsTokenManager.accessToken);
        return;
      }
      requestToken(result.user.phoneNumber, result.user.stsTokenManager.accessToken)
        .then((token) => resolve(token));
    }).catch((error) => {
      console.error(error);
    });
  })
};

