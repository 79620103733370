import React from 'react'
import axios from 'axios'
import {API_DOMAIN, AUTH_TOKEN_KEY} from 'utils/const'
import {authError} from 'actions'

export const callAPI = async (endpoint, data = null, headers = {}, method = null) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)

  const suggestedMethod = data ? 'post' : 'get'
  const requestMethod = method ? method : suggestedMethod
  const requestHeaders = {}
  if (token) {
    requestHeaders['Authorization'] = `Token ${token}`
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: requestMethod,
        url: `${API_DOMAIN || ''}/${endpoint}`,
        data,
        headers: {
          ...requestHeaders,
          ...headers,
        },
      })
      // console.log('response in call api: ', response)
      resolve(response)
    } catch (error) {
      // console.log('error in call api', error)
      reject(error)
    }
  })
}
