import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {createCase} from 'actions/documents'
import {Button} from 'react-bootstrap'
import DocumentDropzone from "../DocumentDropzone/DocumentDropzone";
import upload from '../../services/upload'

const NewCaseForm = ({partner, history, dispatch}) => {
    const [url, setURL] = useState('')
    const [error, setError] = useState(null)
    const [uploading, setUploading] = useState(false)

    const onFileChange = async (e) => {
        if (!e) {
            setURL('')
            return
        }

        setUploading(true)
        try {
            const { local_url } = await upload.uploadDocument(e)
            setURL(local_url)
            setError(null)
        } catch (err) {
            console.error(err)
            setError('Не удалось загрузить документ')
        }
        setUploading(false)
    }

    async function submit () {
        try {
            const response = await dispatch(createCase(url))
            history.push(`/case/${response.data.id}`)
        } catch (err) {
            const { response } = err
            if (!response) return ERR_UPLOAD_FAIL
            const msg = response.data ? (response.data.error || ERR_UPLOAD_FAIL) : ERR_UPLOAD_FAIL
            setError(msg)
        }
    }

    return (
    <div className="NewCase">
        <p>Укажите ссылку на&nbsp;документ, который вы&nbsp;хотите подписать:</p>
        <p>
            <input
              placeholder="Адрес файла"
              id="NewCase__url"
              name="url"
              value={url}
              onChange={e => setURL(e.target.value)}
              style={{width: '350px'}}
            />
      </p>
      <p>
          {/*<Button*/}
          {/*    onClick={() => {*/}
          {/*        dispatch(createCase(url))*/}
          {/*            .then(response => {*/}
          {/*                history.push(`/case/${response.data.id}`)*/}
          {/*            })*/}
          {/*            .catch(error => {*/}
          {/*                setError(error)*/}
          {/*            })*/}
          {/*    }}*/}
          {/*>*/}
          {/*    Загрузить*/}
          {/*</Button>*/}

          <DocumentDropzone onChange={onFileChange} />
          <br />
          <Button
             onClick={submit}
             // disabled={uploading}
          >
              Отправить
          </Button>
      </p>

        {error && <p>Ошибка</p>}
    </div>
    )
}

export default connect(
  null,
  null,
)(withRouter(NewCaseForm))
