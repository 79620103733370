import React from 'react'
import {withRouter} from 'react-router-dom'
import {RegisterProfileForm} from 'components'
import {loadProfile} from 'actions'

const ProfileInit = props => {
  return (
    <div className="ProfileEmpty">
      <h1>Идентификация</h1>

      <div style={{height: 20}} />
      <p style={{maxWidth: 800}}>
        С&nbsp;точки зрения закона, действительна только та&nbsp;сделка, в&nbsp;которой можно достоверно установить, кто
        ее&nbsp;заключил. Мы&nbsp;используем современные технологии искусственного интеллекта, чтобы подтвердить
        личность наших пользователей.
      </p>

      <div style={{height: 20}} />
      <RegisterProfileForm
        onSubmitted={() => {
          props.dispatch(loadProfile(true)).then(() => {
            props.history.push('/profile')
          })
        }}
      />
    </div>
  )
}

export default withRouter(ProfileInit)
