import React, {useEffect, Fragment} from 'react'
import {loadUserDocuments as loadUserDocumentsAction} from 'actions/userDocuments'
import {Spinner} from 'components'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Table, Button} from 'react-bootstrap'

import './Detail.scss'

export const ProfileDetail = ({documents, loading, loadUserDocuments, partner}) => {
  useEffect(() => {
    loadUserDocuments()
  }, [])

  return (
    <div className="ProfileDetail">
      <div className="Header">
        <h1>Мои документы</h1>
        {partner !== null ? (
          <Link className="btn btn-primary" to={`/case/new/`}>
            Добавить
          </Link>
        ) : null}
      </div>
      <div style={{height: 20}} />
      {documents.length > 0 ? (
        <Table bordered>
          <tbody>
            {documents.map(doc => {
              return (
                <tr key={doc.public_id}>
                  <td>
                    <Link to={`/case/${doc.public_id}`}>{doc.name ? doc.name : doc.filename}</Link>
                  </td>
                  <td>
                    <a href={doc.url} target="_blank" download>
                      (скачать)
                    </a>
                  </td>
                  <td>
                    <em>{doc.signed ? 'Заключен' : 'Не заключен'}</em>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <Fragment>{loading ? <Spinner /> : <p>Здесь будут отображаться подписанные вами документы.</p>}</Fragment>
      )}
    </div>
  )
}

ProfileDetail.defaultProps = {
  documents: [],
  loading: true,
  partner: null,
}

export default connect(
  state => ({
    documents: state.userDocuments.data,
    loading: state.userDocuments.loading,
    partner: state.auth.profile.partner,
  }),
  dispatch => ({
    loadUserDocuments: () => dispatch(loadUserDocumentsAction()),
  }),
)(ProfileDetail)
