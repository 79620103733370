import React from 'react'

const NewWindow = ({width, height}) => (
  <svg
    enableBackground="new 0 0 482.239 482.239"
    height="512"
    viewBox="0 0 482.239 482.239"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
    style={{width, height}}
  >
    <path d="m447.793 447.793h-413.347v-413.347h206.674v-34.446h-223.897c-9.52 0-17.223 7.703-17.223 17.223v447.793c0 9.52 7.703 17.223 17.223 17.223h447.793c9.52 0 17.223-7.703 17.223-17.223v-223.896h-34.446z" />
    <path d="m310.011 0v34.446h113.428l-194.496 194.496 24.354 24.354 194.496-194.496v113.428h34.446v-172.228z" />
  </svg>
)

export default NewWindow
