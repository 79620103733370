import React from 'react'

import DataRow from './components/DataRow'
import SignatureForm from './components/SignatureForm'

import {formatDate} from 'utils/date'
import {loadDocument} from 'actions'

import './index.scss'

import bgImage from './images/BG.png'

const formatPartner = partner => {
  if (partner.legal_form === 'organization') {
    return partner.organization_name
  } else {
    let fio = `${partner.last_name} ${partner.first_name}`
    if (partner.patronymic_name) {
      fio = `${fio} ${partner.patronymic_name}`
    }

    if (partner.legal_form === 'entrepreneur') {
      fio = `ИП ${fio}`
    }
    return `${fio}`
  }
}

const Rocketwork = ({data, dispatch}) => {
  return (
    <div className="RocketWork">
      <div className="RocketWork__Bg" style={{backgroundImage: `url(${bgImage})`, backgroundSize: 'cover'}} />

      <header>Рокет Ворк</header>

      <div className="RocketWork__Inner">
        <div className="RocketWork__Case">
          <h1 className="Case__Title">{data.name}</h1>
          <p className="Case__Description">{data.description}</p>

          <div className="Case__Partners">
            <DataRow header="Заказчик">{formatPartner(data.meta.customer)}</DataRow>
            <DataRow header="Исполнитель">{formatPartner(data.meta.executor)}</DataRow>
          </div>

          <DataRow header="Договор на выполнение задания">
            <a href={data.url} target="_blank">
              {data.url}
            </a>
          </DataRow>

          <DataRow header="Кто подписал">
            <ul className="Case__Signatures">
              {data.signatures.map((signature, index) => {
                const isCustomer = index === 0

                return (
                  <li key={signature.inn}>
                    <p>
                      <span>
                        {signature.full_name} (ИНН: {signature.inn}){' '}
                      </span>
                      в {formatDate(signature.date)}
                      {isCustomer ? (
                        <span> по доверенности от "Рокет Ворк"</span>
                      ) : (
                        <span>
                          {' '}
                          при&nbsp;помощи секретного кода, отправленного на&nbsp;номер телефона {signature.phone}
                        </span>
                      )}
                    </p>
                  </li>
                )
              })}
            </ul>
          </DataRow>

          {/*
             <button>Распечатать</button>
          */}

          {data.signed ? null : (
            <SignatureForm
              dispatch={dispatch}
              documentPublicId={data.public_id}
              phoneNumber={data.meta.executor.phone}
              onSuccess={() => {
                dispatch(loadDocument(data.public_id))
              }}
              onReject={error => {
                console.log('error', error)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Rocketwork
