import React, { useState, useRef } from 'react'
import Dropzone from 'arui-feather/dropzone'
import Icon from '../Icon/Icon'
import AppendIcon from '../../icons/append.svg'
import DocumentIcon from '../../icons/document.svg'
import CloseIcon from '../../icons/close.svg'
import './DocumentDropzone.scss'

export default function ({ onChange, className = '', disabled = false }) {
    const [file, setFile] = useState(null)
    const [draged, setDraged] = useState(false)
    const hiddenInput = useRef()

    function change (fileList) {
        // console.log(fileList)
        const newFile = (fileList && fileList.length) ? fileList[fileList.length - 1] : null
        setFile(newFile)
        onChange && onChange(newFile)
    }

    return <Dropzone
        onDrop={change}
        onDragEnter={() => setDraged(true)}
        onDragLeave={() => setDraged(false)}
        className={draged ? "DocumentDropzone DocumentDropzone_active" : "DocumentDropzone"}
    >
        <div className="DocumentDropzone_content">
            <div className="DocumentDropzone_linkWrapper">
                <div className="DocumentDropzone_separator">
                    или
                </div>
                <div
                    className="DocumentDropzone_appendLink"
                    onClick={() => hiddenInput.current.click()}
                    disabled={disabled}
                >
                    <Icon className="DocumentDropzone_appendLinkIcon" icon={AppendIcon}/>
                    <div className="DocumentDropzone_appendLinkText">Прикрепить файл</div>
                    <input
                        type="file"
                        ref={hiddenInput}
                        style={{display: 'none'}}
                        onChange={e => change(e.target.files)}
                    />
                </div>
            </div>

            {file ?
                <div className="DocumentDropzone_files">
                    <div className="DocumentDropzone_file">
                        <div className="DocumentDropzone_fileDelete" onClick={() => change([])}>
                            <Icon className="DocumentDropzone_fileDeleteIcon" icon={CloseIcon}/>
                        </div>
                        <Icon className="DocumentDropzone_fileIcon" icon={DocumentIcon}/>
                        <div className="DocumentDropzone_fileName">{file.name}</div>
                    </div>
                </div>
                :
                <div className="DocumentDropzone_hint">
                    Перенесите документ сюда для быстрой загрузки
                </div>
            }
        </div>
    </Dropzone>
}
