import React from 'react'

const ProfileDenied = props => {
  return (
    <div>
      <h1>Sorry</h1>
      <p>Your profile is not valid to use. Please contact our support.</p>
    </div>
  )
}

export default ProfileDenied
