import axios from 'axios'
import {callAPI} from 'utils/api'
import {API_DOMAIN} from 'utils/const'

export const addDocument = ({id, data}) => {
  return {type: 'DOCUMENTS/ADD', id, data}
}

export const loadingDocument = id => {
  return {type: 'DOCUMENTS/LOADING', id}
}

export const loadDocument = id => {
  return async (dispatch, getState) => {
    dispatch(loadingDocument(id))
    try {
      const url = `case/${id}/`
      const response = await callAPI(url)
      dispatch(
        addDocument({
          id,
          data: response ? response.data : {status: 'NOT_FOUND'},
        }),
      )
    } catch (error) {
      dispatch(
        addDocument({
          id,
          data: {status: 'NOT_FOUND', error},
        }),
      )
    }
  }
}

export const renameDocument = (id, name) => {
  return async (dispatch, getState) => {
    try {
      console.log('id', id)
      const response = await callAPI(`case/${id}/rename`, {name}, {}, 'patch')
      console.log('response', response)
      dispatch({type: 'DOCUMENTS/RENAME', id, name})
    } catch (e) {
      throw new Error('Error while renaming')
    }
  }
}

export const createCase = url => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await callAPI(`case/new/`, {url})
        resolve(response)
      } catch (e) {
        reject(e)
      }
    })
  }
}
