import { loadDocument, renameDocument as renameDocumentAction } from 'actions'
import { CaseNameEdit, LoginAndSignatureForm, NewWindowIcon, SignatureForm, Spinner } from 'components'
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Document, Page } from 'react-pdf/dist/esm/entry.parcel'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { API_DOMAIN } from 'utils/const'
import { formatDate } from 'utils/date'

import DetailRow from './DetailRow'
import DocumentPreview from './DocumentPreview'

import './Detail.scss'

const DocumentDetail = ({dispatch, history, auth, renameDocument, name, ...props}) => {
  const [documentPreviewUrl, setPreviewDocumentUrl] = useState(null)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)

  useEffect(() => {
    if (!props.url) return

    const url = new URL(props.url)
    const hostUrl = new URL(API_DOMAIN)

    if (url.hostname !== '192.168.243.17') return

    url.protocol = 'https'
    url.hostname = hostUrl.hostname
    url.port = ''
    setPreviewDocumentUrl(url.href)
    
  }, [props.url])

  let isPDF = false
  try {
    isPDF =
      props.filename
        .split('.')
        .pop()
        .toLowerCase() === 'pdf'
  } catch (e) {}

  return (
    <div className="DocumentDetail">
      <h2>Сертификат онлайн-подписи</h2>
      <div className="print-only">
        <DetailRow header="Адрес сертфиката">
          <a href="https://sign.legium.io/case/{props.public_id}">https://sign.legium.io/case/{props.public_id}</a>
        </DetailRow>
      </div>

      <DetailRow header="Что подписали">
        {props.signed ? (
          <span>{name ? name : props.filename}</span>
        ) : (
          <CaseNameEdit
            name={name ? name : props.filename}
            onChange={name => {
              renameDocument(props.public_id, name)
            }}
          />
        )}

      </DetailRow>
      {isPDF && documentPreviewUrl && (
        <DetailRow className="no-print">
          <Document file={documentPreviewUrl} onClick={() => setIsPreviewOpen(true)} loading={Spinner}>
            <Page className="DocumentDetail__preview" pageNumber={1} width={180} />
          </Document>
        </DetailRow>
      )}

      <DetailRow
        header={() => (
          <Fragment>
            Контрольная сумма <em>(SHA-256)</em>
          </Fragment>
        )}
      >
        <p style={{wordBreak: 'break-all'}}>{props.hashsum}</p>
      </DetailRow>

      {!documentPreviewUrl && (
        <DetailRow header="Где хранится">
          <a href={documentPreviewUrl || props.url} target="_blank">
            <NewWindowIcon width={10} height={10} />
            <span style={{marginLeft: 5}}>{documentPreviewUrl || props.url}</span>
          </a>
        </DetailRow>
      )}

      <div className="mt-md-5 mt-xl-5 mt-xs-0 mt-sm-0" />
      <DetailRow header="Кто подписал">
        {props.signatures.length > 0 ? (
          <ul className="signatures">
            {props.signatures.map(signature => (
              <li>
                <p>
                  {signature.inn ? (
                    <span>
                      {signature.full_name} (ИНН: {signature.inn}){' '}
                    </span>
                  ) : (
                    <span>Неидентифицированный пользователь </span>
                  )}
                  в {formatDate(signature.date)} при&nbsp;помощи секретного кода, отправленного на&nbsp;номер телефона{' '}
                  {signature.phone}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <div>Документ пока никем не подписан</div>
        )}

        {props.partner.slug === "x5" && props.signatures.length < 1 && props.is_signed_by_user === false && (
          <Fragment>
            <div className="mt-md-3 mt-xl-3 mt-xs-1 mt-sm-1" />
            {auth.token ? (
              <SignatureForm
                documentPublicId={props.public_id}
                demo={props.demo}
                onSuccess={() => {
                  dispatch(loadDocument(props.public_id))
                }}
                onReject={() => {
                  history.push('/profile')
                }}
              />
            ) : (
              <LoginAndSignatureForm
                documentPublicId={props.public_id}
                onSuccess={response => {
                  if (response.signed) {
                    dispatch(loadDocument(props.public_id))
                  } else {
                    history.push('/profile')
                  }
                }}
              />
            )}
          </Fragment>
        )}

        {props.partner.slug !== "x5" && props.is_signed_by_user === false && (
          <Fragment>
            <div className="mt-md-3 mt-xl-3 mt-xs-1 mt-sm-1" />
            {auth.token ? (
              <SignatureForm
                documentPublicId={props.public_id}
                demo={props.demo}
                onSuccess={() => {
                  dispatch(loadDocument(props.public_id))
                }}
                onReject={() => {
                  history.push('/profile')
                }}
              />
            ) : (
              <LoginAndSignatureForm
                documentPublicId={props.public_id}
                onSuccess={response => {
                  if (response.signed) {
                    dispatch(loadDocument(props.public_id))
                  } else {
                    history.push('/profile')
                  }
                }}
              />
            )}
          </Fragment>
        )}

        {props.is_signed_by_user === true && auth.profile && auth.profile.status === 'EMPTY' && (
          <Button
            onClick={() => {
              history.push('/profile')
            }}
          >
            Пройти идентификацию
          </Button>
        )}
      </DetailRow>

      <div className="mt-md-5 mt-xl-5 mt-xs-0 mt-sm-0" />

      <DetailRow header="Как идентифицировали">
        <p>
          Каждый индивидуальный предприниматель и&nbsp;юридическое лицо перечисляет нам со&nbsp;своего счета 1&nbsp;руб.
          Таким образом и&nbsp;происходит идентификация.
        </p>

        <p>
          Когда был открыт счет, сотрудник банка установил и&nbsp;подтвердил личность руководителя организации. Теперь
          любой платеж со&nbsp;счета означает, что это сделал лично руководитель. Оплата комиссии за&nbsp;регистрацию
          в&nbsp;Legium означает подтверждение личности.
        </p>

        <p>
          Физические лица делают фотографию паспорта и&nbsp;селфи с&nbsp;паспортом. Нейронная сеть проверяет фотографии
          на&nbsp;соответствие и&nbsp;наличие подделок. Кроме того, мы&nbsp;проверяем данные паспорта по&nbsp;более чем
          20-ти базам данных и&nbsp;сверяем принадлежность номера телефона данному человеку.
        </p>

        <p>Телефонные номера подтверждаются при регистрации.</p>
      </DetailRow>

      {props.signed && (
        <div className="d-flex justify-content-center mt-5 no-print">
          <Button
            style={{minWidth: 200}}
            onClick={() => {
              window.print()
            }}
          >
            Распечатать
          </Button>
        </div>
      )}

      <DocumentPreview
        isOpen={isPreviewOpen}
        onRequestClose={() => setIsPreviewOpen(false)}
        documentUrl={documentPreviewUrl}
      />
    </div>
  )
}

DocumentDetail.defaultProps = {
  signatures: [],
  demo: true,
  partner: {},
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  dispatch => ({
    renameDocument: (documentId, name) => dispatch(renameDocumentAction(documentId, name)),
  }),
)(withRouter(DocumentDetail))
