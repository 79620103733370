import React, {Fragment} from 'react'
import {Content} from 'components'

const NoMatch = props => {
  console.log('Content', Content)
  return (
    <Content>
      <div className="NoMatch">
        <h1>Ошибка 404</h1>
        <p>Данная страница не найдена.</p>
      </div>
    </Content>
  )
}

export default NoMatch
