import React from 'react'
import { connect } from 'react-redux'

const ProfilePending = ({profile}) => {
  return (
    <div>
      <h1>Спасибо за регистрацию!</h1>

      <p>Мы&nbsp;все проверим и&nbsp;в&nbsp;течение ближайшего времени.</p>
      {(profile.legal_form === 'organization' || profile.legal_form === 'entrepreneur') && (
        <p>
          Не забудьте, что для идентификации необходимо перевести 1₽&nbsp;с&nbsp;вашего расчетного счета в&nbsp;банке
          по&nbsp;этим реквизитам&nbsp;(
          <a target="_blank" href="https://api.legium.io/static/bill.pdf">
            pdf
          </a>{' '}
          или{' '}
          <a target="_blank" href="https://api.legium.io/static/bill.docx">
            docx
          </a>
          )
        </p>
      )}
    </div>
  )
}

export default connect(
  state => ({profile: state.auth.profile}),
  null,
)(ProfilePending)
