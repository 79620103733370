import React from 'react'
import './DataRow.scss'

const DataRow = ({header, children}) => {
  return (
    <div className="Case__DataRow">
      <dt>{typeof header === 'function' ? header() : header}</dt>
      <dd>{children}</dd>
    </div>
  )
}

export default DataRow
