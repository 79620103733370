import React from 'react'

const DocumentError = () => {
  return (
    <div className="DocumentError">
      <h1>Ошибка</h1>
      <p>При обработке файла произошла ошибка.</p>
    </div>
  )
}

export default DocumentError
