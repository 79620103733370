import React from 'react'
import {Row, Col} from 'react-bootstrap'
import './DetailRow.scss'

const DetailRow = ({header, children, className = '', last}) => {
  let classNames = ['pt-3 pt-3 DetailRow', className];

  return (
    <Row className={classNames.join(' ')}>
      <Col
        as={'dt'}
        className="DetailRow__Header text-small text-xs-left text-sm-left text-md-right text-lg-right text-xl-right pr-md-4 pr-xl-4 pr-xs-0 pr-sm-0 pl-xs-0 pl-sm-0"
        xs={12}
        md={4}
        lg={4}
      >
        {typeof header === 'function' ? header() : header}
      </Col>
      <Col className="pl-xs-0 pl-sm-0 pl-md-4 pl-lg-4" as={'dd'} xs={12} md={8} lg={8}>
        {children}
      </Col>
    </Row>
  )
}

export default DetailRow
