import React, {Fragment, useEffect} from 'react'
import {Content, Spinner, DocumentInfo} from 'components'
import {NoMatch} from 'screens'
import {IsSigned} from 'screens'
import {loadDocument} from 'actions'
import {connect} from 'react-redux'

import {DocumentDetail, DocumentError, DocumentInit} from 'components'
import Rocketwork from './Rocketwork'
import RocketSpinner from './Rocketwork/components/Spinner'

const statusToComponent = {
  INIT: DocumentInit,
  PROCESSING: DocumentInit,
  ERROR: DocumentError,
  SUCCESS: DocumentDetail,
}

const Case = ({match, dispatch, documents, auth}) => {
  const {id} = match.params
  const doc = documents && documents[id]

  useEffect(() => {
    dispatch(loadDocument(id))
  }, [id, auth.token])

  let StatusComponent = null

  if (doc && doc.status !== null) {
    if (doc.status === 'NOT_FOUND') {
      return <IsSigned/>
    }
    
    if (doc.data.partner && doc.data.partner.slug === 'rocketwork') {
      return <Rocketwork data={doc.data} dispatch={dispatch} />
    } else {
      StatusComponent = statusToComponent[doc.status]
      return (
        <Content>
          {doc ? (
            <div>{StatusComponent && <StatusComponent id={id} {...doc.data} dispatch={dispatch} />}</div>
          ) : (
            <Spinner />
          )}
        </Content>
      )
    }
  }

  return (
    <div
      style={{display: 'flex', minWidth: '100vw', minHeight: '100vh', alignItems: 'center', justifyContent: 'center'}}
    >
      <RocketSpinner />
    </div>
  )
}

export default connect(
  state => ({
    documents: state.documents,
    auth: state.auth,
  }),
  null,
)(Case)
