import { commitSignature, commitSignatureFirebase, requestSignatureCode } from 'actions'
import { PinCodeForm } from 'components'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { requestTokenTwillio, sendCode, sendPhone } from "../../services/authorization/firebase"

const AUTH_TYPE = {
  FIREBASE: 'FIREBASE',
  TWILLIO: 'TWILLIO',
  ONCALL: 'ONCALL'
}

const REP = {
  SEND: false,
}

const SignatureForm = ({dispatch, demo, auth, match, documentPublicId, onSuccess, onReject}) => {
  const [initialized, setInitialized] = useState(false)
  const [minutes, setMinutes ] = useState(0)
  const [seconds, setSeconds ] =  useState(59)
  const [authType, setAuthType] = useState(AUTH_TYPE.ONCALL)

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  })

  return (
    <div className="SignatureForm">
      <input
        style={{
          display: 'none'
        }}
        value={auth.profile.username}
        // value={auth?.profile?.username}
        id="sign-in-button"
      />
      {initialized ? (
        <>
          {/* {authType === AUTH_TYPE.FIREBASE && (
            <PinCodeForm
              message={'Для продолжения введите код отправленный на'}
              phoneNumber={auth.profile.username}
              code_length={6}
              onSubmit={code => {
                return new Promise((resolve, reject) => {
                  sendCode(code, (token) => {
                    dispatch(commitSignatureFirebase(documentPublicId, token))
                      .then(response => {
                        console.log('success')
                        onSuccess(response)
                      })
                      .catch(error => {
                        console.log('error', error)
                        reject()
                      })
                  })
                })
              }}
            />
          )} */}
          {authType === AUTH_TYPE.TWILLIO && (
            <PinCodeForm
              message={'Для продолжения введите код отправленный на'}
              phoneNumber={auth.profile.username}
              code_length={6}
              onSubmit={code => {
                return new Promise((resolve, reject) => {
                  dispatch(commitSignature(documentPublicId, code))
                    .then(response => {
                      console.log('success')
                      onSuccess(response)
                    })
                    .catch(error => {
                      console.log('error', error)
                      reject()
                    })
                })
              }}
            />
          )}
          {authType === AUTH_TYPE.ONCALL && (
            <PinCodeForm
              message={'Укажите последние 4 цифры номера, с которого будет совершен звонок на'}
              phoneNumber={auth.profile.username}
              code_length={4}
              onSubmit={code => {
                return new Promise((resolve, reject) => {
                  dispatch(commitSignature(documentPublicId, code))
                    .then(response => {
                      console.log('success')
                      onSuccess(response)
                    })
                    .catch(error => {
                      console.log('error', error)
                      reject()
                    })
                })
              }}
            />
          )}

          <div>
            {minutes === 0 && seconds === 0 ? (
              <div style={{cursor: 'pointer'}} onClick={() => {
                const phone = auth.profile.username;
                setPhoneNumber(null);

                if (REP.SEND === false) {
                  dispatch(requestAuthCode(phone));
                  setAuthType(AUTH_TYPE.TWILLIO);
                  REP.SEND = true;
                } else {
                  sendPhone(phone);
                  setAuthType(AUTH_TYPE.FIREBASE);
                }

                // requestTokenTwillio(phone);
                dispatch(requestSignatureCode(documentPublicId)).catch(error => {
                  onReject()
                })

                // setAuthType(AUTH_TYPE.TWILLIO);
                setSeconds(30)
                
                setTimeout(() => {setPhoneNumber(phone);}, 1000)
              }}>Отправить смс раз</div>
            ) : (
              <>
                Отправить смс раз через <b className="Mark">{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</b>
              </>
            )}

          </div>
        </>

      ) : (
        <>
          <Button
            style={{minWidth: 200}}
            onClick={() => {
              if (auth.token !== null) {
                if (auth.profile !== null) {
                  if (auth.profile.status === 'APPROVED' || demo === true) {
                    dispatch(commitSignature(documentPublicId))
                    .then(response => {
                      // window.location.reload();
                      console.log(response)

                      if (response.status === 200) {
                        window.location.reload();
                      }

                      // if (response.status === 204) {
                      //     setPhoneNumber(phone)
                      //     setInitialized(true)
                      // }
                      // onSuccess(response)
                      // onReject()
                    })

                    // let response = dispatch(commitSignature(documentPublicId))
                    // dispatch(requestSignatureCode(documentPublicId)).catch(error => {
                    //   onReject()
                    // })
                    // sendPhone(auth.profile.username)
                  // } else {
                  //   onReject()
                  // }
                  }
                }
              }
            }}
          >
            Подписать
          </Button>
        </>
      )}
    </div>
  )
}

export default connect(
  state => ({auth: state.auth}),
  null,
)(SignatureForm)
