import React from 'react'
import {Container} from 'react-bootstrap'
import {Header} from 'components'

const Content = props => {
  return (
    <div>
      <Header />
      <Container className="content" style={{paddingTop: 50}}>
        <div>{props.children}</div>
      </Container>
    </div>
  )
}

export default Content
