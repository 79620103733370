import React, {useCallback, useState, Fragment} from 'react'
import {useDropzone} from 'react-dropzone'
import {callAPI} from 'utils/api'
import {SpinnerSmall} from 'components'
import {Button} from 'react-bootstrap'
import './DropZoneField.scss'

const DropZoneField = ({field, kind, mediaType}) => {
  const inputValue = field.input.value
  const [isUploaded, setIsUploaded] = useState(false)

  const onDrop = useCallback(async acceptedFiles => {
    try {
      const file = acceptedFiles[0]
      const formData = new FormData()
      formData.append('file', file)
      formData.append('kind', kind)

      setIsUploaded(true)
      const response = await callAPI('profile/identification/', formData, {'Content-Type': 'multipart/form-data'})
      field.input.onChange({
        name: file.name,
        ...response.data,
      })
      setIsUploaded(false)
    } catch (error) {
      if (error.response.status === 401) {
        console.log('error')
      }
    }
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    multiple: false,
    accept: mediaType,
  })

  return (
    <div className="DropZoneField">
      {isUploaded ? (
        <SpinnerSmall />
      ) : (
        <Fragment>
          {inputValue ? (
            <div className="DropZoneField__value">
              <p>{inputValue.name}</p>
              <button
                className="DropZoneField__remove"
                onClick={() => {
                  field.input.onChange(null)
                }}
              >
                ×
              </button>
            </div>
          ) : (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <Button variant="outline-primary" size="xs">
                  Загрузить файл
                </Button>
              )}
            </div>
          )}
          {/*
              {field.meta.touched && field.meta.error && <span className="error">{field.meta.error}</span>}
            */}
        </Fragment>
      )}
    </div>
  )
}

export default DropZoneField
