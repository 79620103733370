import React from 'react'
import {Content, LoginForm} from 'components'

import {connect} from 'react-redux'

const Login = ({history, dispatch, location}) => {
  let prevURL = null

  if (location.state && location.state.from) {
    prevURL = `${location.state.from.pathname}${location.state.from.search ? location.state.from.search : ''}`
  }

  const nextURL = prevURL ? prevURL : '/profile'

  return (
    <Content>
      <h1>Онлайн-подпись документа</h1>
      <LoginForm
        onSuccess={() => {
          history.push(nextURL)
        }}
      />
    </Content>
  )
}

export default connect(
  state => ({token: state.auth.token}),
  null,
)(Login)
