import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import {Provider} from 'react-redux'
import {PrivateRoute, AuthorizedRoute} from 'components'
import {Login, Profile, Case, NoMatch, IsSigned, Upload} from 'screens'
import {store} from './store'
import {loadProfile} from 'actions'

//  временное решение
import './App.scss'

export default function App() {
  useEffect(() => {
    store.dispatch(loadProfile())
  }, [])

  return (
    <Provider store={store}>
      <div>
        <Router>
          <Switch>
            <PrivateRoute exact path="/profile" component={Profile} />
            <AuthorizedRoute exact path="/case/new/" component={Upload} />
            <Route path="/case/:id" component={Case} />
            <Route exact path="/login" component={Login} />
            <Redirect exact from="/" to="/profile" />
            <Route path="*" component={NoMatch} />
          </Switch>
        </Router>
      </div>
    </Provider>
  )
}
