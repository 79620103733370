import React from 'react'

const Logo = () => {
  return (
    <h1>
      <a href="/" style={{fontWeight: 200, fontSize: '0.8em', color: 'black', textDecoration: 'none'}}>
        legium
      </a>
    </h1>
  )
}

export default Logo
