import React from 'react'
import {connect} from 'react-redux'
import {Content, ProfileDetail, ProfileDenied, ProfileInit, ProfilePending} from 'components'

const statusToComponent = {
  APPROVED: ProfileDetail,
  DENIED: ProfileDenied,
  EMPTY: ProfileInit,
  PENDING: ProfilePending,
}

const Profile = ({profile, dispatch}) => {
  let status = null
  if (profile) {
    status = profile.status
  }
  const StatusComponent = statusToComponent[status]

  return <Content>{StatusComponent && <StatusComponent dispatch={dispatch} />}</Content>
}

export default connect(
  state => ({profile: state.auth.profile}),
  null,
)(Profile)
