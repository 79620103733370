import React, {useState, useEffect, useRef} from 'react'
import Modal from 'react-modal'
import {Document, Page} from 'react-pdf/dist/esm/entry.parcel'
import {Button} from 'react-bootstrap'
import Icon from '../Icon/Icon'
import CloseIconBlack from '../../icons/close.svg'
import CloseIconWhite from '../../icons/close-white.svg'
import Spinner from '../Spinner/Spinner'
import './DocumentPreview.scss'

export default function({isOpen, onRequestClose, sign, documentUrl}) {
  const documentRef = useRef(null)
  const [numPages, setNumPages] = useState(null)
  const [documentWidth, setDocumentWidth] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    function resize() {
      setWindowWidth(window.innerWidth)
      setDocumentWidth(windowWidth * (windowWidth < 1024 ? 0.8 : 0.4))
    }

    resize()
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  })

  function onDocumentLoadSuccess({numPages: nextNumPages}) {
    setNumPages(nextNumPages)
  }

  function print() {
    documentRef.current.contentWindow.print()
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 0,
          backgroundColor: 'rgba(0, 0, 0, 0)',
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
        },
        overlay: {
          backgroundColor: windowWidth < 1024 ? '#FFFFFF' : 'rgba(0, 16, 61, 0.88)',
          overflowY: 'hidden',
        },
      }}
      appElement={document.querySelector('#root')}
    >
      <div className="DocumentPreview_close" onClick={onRequestClose}>
        <Icon icon={windowWidth < 1024 ? CloseIconBlack : CloseIconWhite} alt="Закрыть предпросмотр" />
      </div>

      <Document
        className="DocumentPreview_document"
        file={documentUrl}
        loading={Spinner}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            className="DocumentPreview_documentPage"
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            width={documentWidth}
          />
        ))}
      </Document>

      <div className="DocumentPreview_footer">
        <a href={documentUrl} className="DocumentPreview_documentLink" target="_blank">
          {documentUrl}
        </a>
      </div>

      <iframe src={documentUrl} ref={documentRef} style={{display: 'none'}} />
    </Modal>
  )
}
