import React from 'react'
import {createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import {rootReducer as reducer} from 'reducers'
import {Router} from 'react-router-dom'
import {createMemoryHistory} from 'history'
import thunk from 'redux-thunk'

const Story = ({children, state = {}}) => {
  require('bootstrap/dist/css/bootstrap.min.css')
  const store = createStore(reducer, state, applyMiddleware(thunk))
  const history = createMemoryHistory({})
  return (
    <Provider store={store}>
      <div>
        <Router history={history}>{children}</Router>
      </div>
    </Provider>
  )
}

export default Story
